.body{
  color: #2c2c2c !important;
}
.pad-top-xs{
  padding-top: 10px !important;
}
.pad-xs{
  padding: 10px !important;
}
.mar-top-xs{
  margin-top: 10px !important;
}
.mar-top-sm{
  margin-top: 10px !important;
}
.mar-top-md{
  margin-top: 20px !important;
}
.mar-bottom-md{
  margin-bottom: 20px !important;
}
.mar-bottom-xs{
  margin-bottom: 10px !important;
}
.mar-bottom-sm{
  margin-bottom: 10px !important;
}
.mar-top-lg{
  margin-top: 30px !important;
}
.mar-bottom-lg{
  margin-bottom: 30px !important;
}
.no-mar{
  margin: 0 !important;
}
.no-padding{
  padding: 0 !important;
}
.icon-color{
  color:black;
}
.d-flex{
  display: flex!important;
}
.pinned-item-desc {
  flex: 1 0 auto;
}
.no-decoration{
  text-decoration: none;
}
a {
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  text-decoration: none !important;
}
.organization-widget-pad{
  padding: 2px !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
}
.organization-widget-pad > .col-md-2{
  padding: 0;
  margin: 1px;
  text-align: center;
}
.nav-color{
  background-color:#24292e !important;
}
.rep-card{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.rep-card > .card{
  padding: 10px;
  margin:5px;
}
#rep_card{
  max-height: 250px;
}
.card-icon{
  vertical-align: text-bottom;
}

.card-title{
  margin-left: 10px;
  font-size: 1.2rem;
  font-weight: 600;
}
.app-card-footer{
    width: 100%;
}
.app-card-header{
  margin-bottom: 10px;
}
.rep-skills-container{
  overflow: hidden;
  float: left;
  display: inline-block;
  width: 70%;
  max-height: 30px;
  position: relative;
}
.rep-skills-container:after {
  content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}
.app-card-footer >.rep-skills-container > div{
  margin-right: 10px;
  display: inline;
}
.rep-card .list-group-item{
  padding: 0.5rem .5rem;
} 
.language-mark{
  width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
    margin-left: 5px;
    display: inline-block;
}

.skill-page .list-group-item{
  padding:0.55rem 0.25rem;
}
.app-text-truncate{
  display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 0.9rem;
}
.modal-50w{
  max-width: 50vw !important;
}
.organization-profile > .row .title-container .title{
  font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0px;
}
.timeline-expend-icon{
  color: inherit !important;
  float: right;
    font-size: 1rem;
}
.timeline-expend-icon:focus{
  outline:none;
}
.ovr_title{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ovr_repository{
  padding: 5px !important;
}
.box-container{
  display: flex;
    width: 100%;
    flex-direction: column;
  background-color: #fff;
    border: 1px solid #e1e4e8;
    border-radius: 6px;
}
.box-container .card-title{
  font-size: 1rem;
}
.axis {
  stroke-width: .2;
  stroke: grey;
}
.scale {
  stroke-width: .2;
  stroke: grey;
}
.shape {
  fill: tomato;
    width: 0px;
    fill-opacity: 0.2;
    stroke-width: 1;
    stroke: tomato;
}
.shape:hover {
  fill-opacity: .4;
}
.caption{
  font-size: 4.5px!important;
}
.full-width{
  width: 100%;
}

/* Resume Page Style */
.resume-iframe{
  width: 100%;
  height: 85vh;
}

.contact-list{
  list-style: none;
  padding: 0;
}
.contact-list .contact-list-item{
  list-style: none;
}
.contact-list .contact-list-item > a{
  color: inherit;
}
.contact-list .contact-list-item > a:hover{
  color: inherit;
  text-decoration: underline !important;
}
.contact-list .contact-list-item > a > small{
  vertical-align: middle;
}
.normal-link{
  color: inherit !important;
}
.normal-link:hover{
  color: inherit;
  text-decoration: underline !important;
}

.profile-contact-list {
  display: flex;
}
.profile-contact-list .contact-list-item{
  margin-right: 10px;
}
.circle{
  border-radius: 50% !important;
}
.rounded-rectangle{
  border-radius: 5px !important;
}
.contact-element-style{
   color: "#666"; vertical-align: "middle";
}
.svg-path-stroke-white > path{
stroke: white;
}
.underline-nav-overflow{
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}
.underline-nav-overflow::-webkit-scrollbar {
  display: none;  /* Optional: just make scrollbar invisible */
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .profile-contact-list {
    flex-direction:column;
  }
  .exp-filter-list{
    display: flex;
    overflow-x: scroll;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .profile-contact-list {
    flex-direction:column;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .profile-contact-list {
    flex-direction:column;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .profile-contact-list {
    flex-direction:row;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .profile-contact-list {
    flex-direction:row;
  }
}

.pwa-alert{
  position: absolute !important;
  z-index: 999;
    max-width: 300px;
    right: 10px;
    top: 10px;
}

#pwaAlert{
  opacity: 1;
  display: block;
  transition: opacity 1s;
}
#pwaAlert.fade {
  opacity: 0;
  display: none;
}